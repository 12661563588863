<template>
  <div style="height: 100%;">
    <RouterView />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'LayoutAgreements',
  computed: {
    ...mapState('service', ['language'])
  },
  watch: {
    language: {
      immediate: true,
      handler (val, oldVal) {
        if (val && val !== oldVal) {
          this.$i18n.locale = val
          localStorage.setItem('language', val)
        }
      }
    }
  },
  created () {
    const lang = this.$route.query.lang || 'en'
    this.SET_LANGUAGE(lang)
  },
  methods: {
    ...mapMutations('service', ['SET_LANGUAGE'])
  }
}
</script>
